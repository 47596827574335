import {  Fragment } from "react"; //Component, (front of fragment)
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

// import HeaderThree from "../component/layout/header-3";
// import About from "../component/section/about";
// import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
// import Blog from "../component/section/blog";
import Category from "../component/section/category";
import Course from "../component/section/course";
// import Instructor from "../component/section/instructor";
import Sponsor from "../component/section/sponsor";
import Sponsor2 from "../component/section/sponsor-2";
// import Student from "../component/section/student";

// import { Link } from "react-router-dom";
import LicenseSua from "../component/section/license-sua";
import LicenseSua2 from "../component/section/license-sua-2";

// const title = "Enroll Now";
// const socialTitle = "Register With Social Media";
// const btnText = "Enroll";


// let socialList = [
//     {
//         link: '#',
//         iconName: 'icofont-facebook',
//         className: 'facebook',
//     },
//     {
//         link: '#',
//         iconName: 'icofont-twitter',
//         className: 'twitter',
//     },
//     {
//         link: '#',
//         iconName: 'icofont-linkedin',
//         className: 'linkedin',
//     },
//     {
//         link: '#',
//         iconName: 'icofont-instagram',
//         className: 'instagram',
//     },
// ]
const Home = () => {
    return (
        <Fragment>
            <Header />
            {/* <HeaderThree /> */}
            <Banner />
            <Category />
            <Course />
            <LicenseSua />
            <LicenseSua2 />
            <Sponsor />
            {/* <Sponsor2 /> */}
            {/* <About /> */}
            {/* <Instructor /> */}
            {/* <Student />  */}
            {/* <Blog /> */}
            {/* <Achievement /> */}
            <Footer />
        </Fragment>
    );
}

export default Home;