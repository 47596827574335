import {  Fragment } from "react"; //Component,
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderLicensing from"../component/layout/pageheader-licensing"
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import CourseSideCategory from "../component/sidebar/course-category";
import CourseLicensing from "../component/sidebar/course-licensing";
// import Respond from "../component/sidebar/respond";

const dropDown = {
    backgroundColor: '#4D9076',
}
const iconColor = {
    color: '#4D9076',
}
const Licensing = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderLicensing />
            <div className="interior-design-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Course Overview</h3>
                                            <p>Initiated by the Society of Interior Designers in Malaysia, the SUA and 369 Licensing Scheme were created to elevate the interior design profession. They achieve this by clearly defining essential skill sets and competencies, bridging the gap between education and practical application.</p>
                                            <p>The implementation of this scheme, along with adherence to best practices in interior design, will contribute to maintaining elevated standards. Collaborating with EDMUN Academy provides practitioners with the opportunity to enhance their skills, expand their capacity.</p>
                                            <p>There are currently two types of licensing progrmas, Gold and Silver. The Gold program is meant for Interior Designers with 2 years worth of experience(degree not required), while the Silver program is for fresh graduates that have recently completed their undergraduate studies in Interior Design.</p>
                                            <h4>What This Course Provides:</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Interior Design and Technology</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Professional Practice</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Project and Contract Management</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Job Security</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Quality Training</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Maintenance of Standards</li>
                                            </ul>
                                            <p>Obtaining a license through the SUA and 369 Licensing Scheme enhances job security for interior designers. Clients and employers are more likely to trust and hire licensed professionals, providing a stable foundation for career growth.</p>
                                            <p>We also ensure good training by defining core skill sets and competencies. This structured approach guarantees that interior designers receive comprehensive and standardized training, equipping them with the necessary skills to excel in their roles.</p>
                                            <p>This licensing program plays a crucial role in maintaining high industry standards. By setting clear expectations and guidelines, it ensures that interior designers adhere to best practices, fostering a culture of excellence and professionalism within the industry. This, in turn, benefits both practitioners and the clients they serve</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="course-video">
                                    <div className="course-video-title">
                                        <h4>Course Content - Silver</h4>
                                    </div>
                                    <div className="course-video-content">
                                        <div className="accordion" id="accordionSilver">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordionSilver01">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolistSilver1" aria-expanded="true" aria-controls="videolistSilver1" style={dropDown}><span>1. Interior Design and Technology</span> <span>4 lessons</span> </button>
                                                </div>
                                                <div id="videolistSilver1" className="accordion-collapse collapse show" aria-labelledby="accordionSilver01" data-bs-parent="#accordionSilver">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.1 Fundamentals of Design</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.2 Design Styles</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.3 Building Regulations/Fire and Safety Regulations</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.4 Design Communication</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordionSilver02">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolistSilver2" aria-expanded="true" aria-controls="videolistSilver2" style={dropDown}><span>2. Professional Practice</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolistSilver2" className="accordion-collapse collapse " aria-labelledby="accordionSilver02" data-bs-parent="#accordionSilver">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.1 Code of Conduct - Customer and Contractor Relations</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.2 Professional Fees and Standard Contracts</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.3 Professional Development – Business Communication</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordionSilver03">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolistSilver3" aria-expanded="true" aria-controls="videolistSilver3" style={dropDown}><span>3. Project and Contract Management</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolistSilver3" className="accordion-collapse collapse " aria-labelledby="accordionSilver03" data-bs-parent="#accordionSilver">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.1 Management of Suppliers and Contractors</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.2 Quality and Cost Control</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.3 Timeline scheduling and Time management</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordionSilver04">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolistSilver4" aria-expanded="true" aria-controls="videolistSilver4" style={dropDown}><span>4. Site Visit</span> <span>On-Site</span> </button>
                                                </div>
                                                {/* <div id="videolistSilver4" className="accordion-collapse collapse " aria-labelledby="accordionSilver04" data-bs-parent="#accordionSilver">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.1 Impact of furniture and soft furnishings on the overall environment</div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.2 Tips for selecting the right pieces</div>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordionSilver05">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolistSilver5" aria-expanded="true" aria-controls="videolistSilver5" style={dropDown}><span>5. Research Report</span> <span>On-Site</span> </button>
                                                </div>
                                                {/* <div id="videolistSilver5" className="accordion-collapse collapse " aria-labelledby="accordionSilver05" data-bs-parent="#accordionSilver">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.1 Impact of furniture and soft furnishings on the overall environment</div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.2 Tips for selecting the right pieces</div>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseLicensing />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Licensing;