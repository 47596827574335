
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const subTitle = "START YOUR SUCCESS WITH EDMA";
const title = "Accredited and Registered by";

const sponsorList = [
    {
        imgUrl: '../assets/images/sponsor/Logo_01.png',
        imgAlt: 'sponsor 1',
    },
    {
        imgUrl: '../assets/images/sponsor/Logo_02.png',
        imgAlt: 'sponsor 2',
    },
    {
        imgUrl: '../assets/images/sponsor/Logo_03.png',
        imgAlt: 'sponsor 3',
    },
]


const Sponsor = () => {
    return (
        <div className="sponsor-section section-bg">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div style={{display: "flex", justifyContent: "center"}} className="sponsor-slider">
                        {sponsorList.map((val, i) => (
                            <div className="sponsor-item">
                                <div className="sponsor-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sponsor;