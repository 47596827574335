
// import { Link } from "react-router-dom";

const title = "Why EDMA?";
// const btnText = "Browse All Programs";

const catSection = {
    backgroundColor: '#fff9f1',
}
const catItemsSpan ={
    textAlign:'justify',
}
const catItemsHeading ={
    textAlign: 'left',
    paddingBottom: 10,
}
const catItemsInner = {
    textAlign:'justify',
    padding: 15,
}

const catItems = [
    {
        title:'DIRECT INDUSTRY EXPERTISE',
        desc:'Training led entirely by experienced interior design professionals.',
    },
    {
        title:'PRACTICAL EXPERIENCE FOCUS',
        desc:'Opportunities for real-world project involvement and professional skill application.',
    },
    {
        title:'LICENSING AND MENTORSHIP',
        desc:'Unique initiatives for career advancement and industry collaboration.',
    },
    {
        title:'COLLABORATION OPPORTUNITIES',
        desc:'Ample chances for teamwork and partnerships within the industry',
    },
]


// const categoryList = [
//     {
//         imgUrl: 'assets/images/category/icon/directexpertise.png',
//         imgAlt: 'program direct industry expertise',
//         title: 'Direct Industry Expertise',
//         count: '1 Course',
//     },
//     {
//         imgUrl: 'assets/images/category/icon/practicalexperience.png',
//         imgAlt: 'program practical experience focus',
//         title: 'Practical Experience Focus',
//         count: '2 Courses',
//     },
//     {
//         imgUrl: 'assets/images/category/icon/mentorship.png',
//         imgAlt: 'program licensing and mentorship',
//         title: 'Licensing And Mentorship',
//         count: '3 Courses',
//     },
//     {
//         imgUrl: 'assets/images/category/icon/collab.png',
//         imgAlt: 'program collab',
//         title: 'Collaboration Opportunities',
//         count: '2 Courses',
//     },

    
//     // commented the ones below because only 2 categories at the moment
//     // {
//     //     imgUrl: 'assets/images/category/icon/03.jpg',
//     //     imgAlt: 'category rajibraj91 rajibraj',
//     //     title: 'Business Analysis',
//     //     count: '27 Course',
//     // },
//     // {
//     //     imgUrl: 'assets/images/category/icon/04.jpg',
//     //     imgAlt: 'category rajibraj91 rajibraj',
//     //     title: 'Data Science Analytics',
//     //     count: '28 Course',
//     // },
//     // {
//     //     imgUrl: 'assets/images/category/icon/05.jpg',
//     //     imgAlt: 'category rajibraj91 rajibraj',
//     //     title: 'Learning Management',
//     //     count: '78 Course',
//     // },
//     // {
//     //     imgUrl: 'assets/images/category/icon/06.jpg',
//     //     imgAlt: 'category rajibraj91 rajibraj',
//     //     title: 'Computer Engineering',
//     //     count: '38 Course',
//     // },
// ]


const Category = () => {
    return (
        <div className="category-section padding-tb" id="programs" style={catSection}>
            <div className="container">
                <div className="section-header text-center">
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-2 justify-content-center row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1"> {/*(changed xl-6 to xl-4 and md-3 to md-2 and added lg-4)row-cols integer is the number of columns for the row. The size xl,md,etc. follows bootstrap*/}
                        {catItems.map((val, i) => (
                            <div className="col category-border" key={i} > {/*style={{ borderRight: i < catItems.length - 1 ? '1px solid #000' : 'none'}}*/}
                                <div className="col-border" style={catItemsInner}>
                                    <h4 style={catItemsHeading}>{val.title}</h4>
                                    <span style={catItemsSpan}>{val.desc}</span>
                                </div>
                            </div>
                        ))}
                        
                        {/* {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="#"><h6>{val.title}</h6></Link>
                                            <span style={{color: "black"}}>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))} */}
                    </div>
                    {/* <div className="text-center mt-5">
                        <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                    </div> */}  {/*commented this part because no categories*/}
                </div>
            </div>
        </div>
    );
}
 
export default Category;