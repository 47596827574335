
import { Link } from "react-router-dom";
// import Rating from "../sidebar/rating";


const subTitle = "Loved by 200,000+ students";
const title = "Student Testimonials";


const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/edma1.jpg',
        imgAlt: 'Student Esther',
        name: 'Esther Ng',
        degi: 'Design Consultant for S.U.A. IIEM. FIND.',
        desc: 'I am an Interior Designer with 28 + years’ experience. My career includes working at major design Consultant firms before setting up my own with the opportunity to be part of S.U.A Pilot for further collaboration. Besides, I have invested several years of learning Classic Feng Shui & Numerology that allow me to incorporate into my designs the benefits of environmental energy to benefit my clients and their business.',
    },
    {
        imgUrl: 'assets/images/feedback/student/edma1.jpg',
        imgAlt: 'Student Esther',
        name: 'Esther Ong',
        degi: 'Design Consultant for S.U.A. IIEM. FIND.',
        desc: 'I am an Interior Designer with 28 + years’ experience. My career includes working at major design Consultant firms before setting up my own with the opportunity to be part of S.U.A Pilot for further collaboration. Besides, I have invested several years of learning Classic Feng Shui & Numerology that allow me to incorporate into my designs the benefits of environmental energy to benefit my clients and their business.',
    },
]


const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col align-self-center">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    <img src="assets/images/feedback/edma1.png" alt="student feedback" />
                                    <a href="https://www.youtube.com/watch?v=nHPWMAsjp0Y&ab_channel=EdMun" className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="#"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            {/* <div className="sft-right">
                                                <Rating />
                                            </div> */}
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;