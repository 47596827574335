import {  Fragment } from "react"; //Component,
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderMentorshipPro from "../component/layout/pageheader-mentorship-pro";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import CourseSideCategory from "../component/sidebar/course-category";
import CourseMentorshipPro from "../component/sidebar/course-mentorship-pro";
// import Respond from "../component/sidebar/respond";

const dropDown = {
    backgroundColor: '#F68B1F',
}
const iconColor = {
    color: '#F68B1F',
}
const MentorshipPro = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderMentorshipPro />
            <div className="interior-design-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Course Overview</h3>
                                            <p>Embark on a six-month journey tailored to empower individuals at every stage of their professional trajectory. Our program is designed to elevate your skills, mindset, and industry knowledge, providing a holistic approach to personal and career development.</p>
                                            <p>This Mentorship program is not only for junior enthusiast stepping into the industry, but is also for seasoned professional seeking a transformative leap, our program caters specifically to your needs. With a curriculum structured around mental development, industry sustainability, self-worth assessment, and professional skill amplification, we ensure a comprehensive learning experience.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Mental Development</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Industry Sustainability</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Know Your Worth</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Professional Skills Enhancement</li>
                                            </ul>
                                            <p>
                                                Our participants have the option to choose an easy payment scheme to make the program financially accessible, receive personalised guidance on real-world projects, applying theoretical knowledge to practical scenarios, benefit from project referrals with many other benefits! 
                                            </p>
                                            <p>
                                                Upon completion, participants will possess a robust skill set and knowledge base necessary to excel as interior stylists or entrepreneurs in the competitive realm of interior design.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="course-video">
                                    <div className="course-video-title">
                                        <h4>Course Content</h4>
                                    </div>
                                    <div className="course-video-content">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion01">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist1" aria-expanded="true" aria-controls="videolist1" style={dropDown}><span>1. Self-Discovery and Brand Identity</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolist1" className="accordion-collapse collapse show" aria-labelledby="accordion01" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.1 Personal Growth Journey</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.2 Defining Your Brand</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.3 Mentorship Session</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion02">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist1" style={dropDown}><span>2. Business Acumen and Financial Wisdom</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolist2" className="accordion-collapse collapse " aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.1 Business Planning Workshop</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.2 Financial Management Coaching</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.3 Mentor Q&A</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion03">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist3" aria-expanded="true" aria-controls="videolist3" style={dropDown}><span>3. Effective Marketing and Client Relationships</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolist3" className="accordion-collapse collapse " aria-labelledby="accordion03" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.1 Marketing Strategy Development</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.2 Building Client Trust</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.3 Mentor Feedback</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion04">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist4" aria-expanded="true" aria-controls="videolist4" style={dropDown}><span>4. Navigating Challenges and Negotiation</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolist4" className="accordion-collapse collapse " aria-labelledby="accordion04" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.1 Handling Difficult Scenarios</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.2 Negotiation Skills Workshop</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.3 Mentor Support</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion05">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist5" aria-expanded="true" aria-controls="videolist5" style={dropDown}><span>5. Implementation and Continuous Growth</span> <span>3 lessons</span> </button>
                                                </div>
                                                <div id="videolist5" className="accordion-collapse collapse " aria-labelledby="accordion05" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.1 Project Management Simulation</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.2 Ongoing Mentorship</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.3 Peer Learning Groups</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className="accordion-item">
                                                <div className="accordion-header" id="accordion06">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist6" aria-expanded="true" aria-controls="videolist6" style={dropDown}><span>6. Starting your own Interior Design Business</span> <span>5 lessons</span> </button>
                                                </div>
                                                <div id="videolist6" className="accordion-collapse collapse " aria-labelledby="accordion06" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">6.1 Pricing and charging strategies</div>
                                                            
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">6.2 Earning potential as an interior stylist</div>
                                                            
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">6.3 Methods to achieve five-figure monthly income</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                            {/* <div className="accordion-item">
                                                <div className="accordion-header" id="accordion02">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist2"> <span>2.How to Create Mixed Media Art in Adobe Photoshop</span> <span>5 lessons, 52:15</span> </button>
                                                </div>
                                                <div id="videolist2" className="accordion-collapse collapse" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.1 Using Adjustment Layers 06:20 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.2 Building the composition 07:33 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.3 Photoshop Lighting effects 06:30 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.4 Digital Painting using photoshop brushes 08:34 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.5 Finalizing the details 10:30 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <Author />
                                <Comment />
                                <Respond /> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseMentorshipPro />
                                {/* <CourseSideCategory /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default MentorshipPro;