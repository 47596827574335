import { Fragment, useState } from "react";
// import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import { Form } from "react-bootstrap";

const title = "Register Now";
const btnText = "Submit";

const pStyle = {
    textAlign: 'left',
}
const divStyle ={
    padding: '0px 15px 0px 0px'
}
const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
}
const labelLeft = {
    display: 'flex',
    textAlign: 'left',
}
const formWidth = {
    maxWidth: '60%',
}
const RegisterPage = () => {

    const [formData, setFormData] = useState({
        selectProgram: '',
        selectedIntake: '',
        firstName: '',
        middleName: '',
        lastName: '',
        DOB: '',
        IC: '',
        contactNumber: '',
        address1: '',
        address2: '',
        address3: '',
        universityName: '',
        qualification: '',
        startYear: '',
        endYear: '',
    });
    const [programs] = useState([
        {
            id: 'mentorshipprogramel',
            name: 'Mentorship Program (EL)',
            intakes: ['April', 'April']
        },
        {
            id: 'mentorshipprogrampl',
            name: 'Mentorship Program (PL)',
            intakes: ['April', 'April']
        },
        {
            id: 'licensingprogramel',
            name: 'Licensing Program (EL)',
            intakes: ['April', 'April']
        },
        {
            id: 'licensingprogrampl',
            name: 'Licensing Program (PL)',
            intakes: ['April', 'April']
        },
        {
            id: 'interiorstyling',
            name: 'Interior Styling',
            intakes: ['April', 'April']
        },
        {
            id: 'architecture',
            name: 'Architecture (TVET)',
            intakes: ['April', 'April']
        },
        {
            id: 'interiordesign',
            name: 'Interior Design (BTEC)',
            intakes: ['April', 'April']
        },
        // {
        //     id: 'interiorstyling',
        //     name: 'SKM ARCHITECTURAL DRAUGHTING ( BC-050-3:2013) - Level 3',
        //     intakes: ['March-April 2024']
        // },
    ]);

    const programHandling = (e) => {
        const programId = e.target.value;
        setFormData({ ...formData, selectProgram:programId});
    };
    
    const intakeHandling = (e) => {
        const selectedIntake = e.target.value;
        setFormData({ ...formData, selectedIntake });
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Access the selected file
        // You can perform actions with the selected file here (e.g., store it in state)
        console.log('Selected File:', file);
        // Update your form data or perform other operations as needed
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    //ori
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('../../public/assets/php/register_form.php', {
                method: 'POST',
                header:{
                    'Content-Type': 'application/json'
                },
                body: new FormData(e.target),
            });
            if (response.ok) {
                const data = await response.json();
                
                console.log('Success:', data);
                
                // Handle success (e.g., show a message, redirect, etc.)
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle errors (e.g., show error message)
        }
    };
    //try 1
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await fetch('../../public/assets/php/register_form.php', {
    //             method: 'POST',
    //             headers: {
    //                 // No need to specify Content-Type for form data
    //             },
    //             body: new FormData(e.target),
    //         });
    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log('Success:', data);
    //             // Handle success (e.g., show a message, redirect, etc.)
    //         } else {
    //             throw new Error('Network response was not ok.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         // Handle errors (e.g., show error message)
    //     }
    // };

    //try 2
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const formData = new FormData(e.target);
    //         const response = await fetch('../../public/assets/php/register_form.php', {
    //             method: 'POST',
    //             body: formData,
    //         });
    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log('Success:', data);
    //             // Handle success (e.g., show a message, redirect, etc.)
    //         } else {
    //             throw new Error('Network response was not ok.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         // Handle errors (e.g., show error message)
    //     }
    // };
    
    const selProgramObj = programs.find(program => program.id === formData.selectProgram) || {intakes: []};
    
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Register Now'} curPage={'Register'} />
            
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper" style={formWidth}>
                        <h3 className="title">{title}</h3>
                        <p style={pStyle}>Instructions:<br/>
                            1.This form contains three (3) parts, please fill out this form before submission.<br/>
                            2.The required fields are marked with an asterisk (*).<br/>
                            3.Kindly check and make sure contact informations are correct, our staff will use these information to get in touch with you.<br/>
                            4.No amendment is allowed after you submit your application.<br/>
                            *APPLICATION & REGISTRATION FEES : <b>RM200.00</b> <br/>
                            Bank Account Number: <b>640320010030279</b> (Alliance Islamic Bank) <br/>
                            Account Name : <b>ED Mun Academy Sdn Bhd</b>
                        </p>
                        <form className="account-form" onSubmit={handleSubmit}>
                            {/* Part 1: Intake and Program */}
                            <div className="form-section">
                                <h2>Intake and Program</h2>
                                <div className="form-group">
                                    <label style={labelLeft}>Program* :</label>
                                    <select name="selectProgram" id="program" value={formData.selectProgram} onChange={programHandling}>
                                        <option value="">Select Program</option>
                                            {programs.map(program => (
                                                <option key={program.id} value={program.id}>{program.name}</option>
                                            ))}
                                    </select>
                                    <label style={labelLeft} htmlFor="intake">Intake:</label>
                                    <select name="selectedIntake" id="intake" value={formData.selectedIntake} onChange={intakeHandling}>
                                        {selProgramObj.intakes.map((intake, index) => (
                                        <option key={index} value={intake}>{intake}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Part 2: Personal Information */}
                            <div className="form-section">
                                <h2>Personal Information</h2>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">
                                            <label style={labelLeft}>First Name* :</label>
                                            <input type="text" name="firstName" placeholder="First Name" required />
                                        </div>
                                        <div className="col-6">
                                            <label style={labelLeft}>Middle Name:</label>
                                            <input type="text" name="middleName" placeholder="Middle Name" />
                                        </div>
                                        <div className="col-6">
                                            <label style={labelLeft}>Last Name* :</label>
                                            <input type="text" name="lastName" placeholder="Last Name" required/>
                                        </div>
                                        <div className="col-6">
                                            <label style={labelLeft}>Email* :</label>
                                            <input type="email" name="email" placeholder="email@edmunacademy.com.my" required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={labelLeft}>Date of Birth* :</label>
                                    <input type="date" name="DOB" required />
                                </div>
                                <div className="form-group">
                                    <label style={labelLeft}>ID Number (IC)* :</label>
                                    <input type="text" name="IC" placeholder="000000-00-0000" required />
                                </div>
                                <div className="form-group">
                                    <label style={labelLeft}>Contact Number* :</label>
                                    <input type="tel" name="contactNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890" required />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">
                                            <label style={labelLeft}>Address Line 1* :</label>
                                            <input type="text" name="address1" placeholder="Address Line 1*" required />
                                        </div>
                                        <div className="col-6">
                                            <label style={labelLeft}>Address Line 2 (Optional):</label>
                                            <input type="text" name="address2" placeholder="Address Line 2" />
                                        </div>
                                        <div className="col-6">
                                            <label style={labelLeft}>Address Line 3 (Optional):</label>
                                            <input type="text" name="address3" placeholder="Address Line 3" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Part 3: Educational Qualifications */}
                            <div className="form-section">
                                <h2>Educational Qualifications</h2>
                                <div className="form-group">
                                    <label style={labelLeft}>Education Facility Name</label>
                                    <input type="text" name="UniversityName" placeholder="University Name" />
                                </div>
                                <div className="form-group">
                                    <label style={labelLeft}>Qualifications:</label>
                                    <div className="radio-group" style={rowStyle}>
                                        <div style={divStyle}>
                                            <label htmlFor="qualification1">Highschool</label>
                                            <input type="radio" id="qualification1" name="qualification" value="Qualification 1" />
                                        </div>
                                        <div style={divStyle}>
                                            <label htmlFor="qualification2">Diploma</label>
                                            <input type="radio" id="qualification2" name="qualification" value="Qualification 2" />
                                        </div>
                                        <div style={divStyle}>
                                            <label htmlFor="qualification3">University</label>
                                            <input type="radio" id="qualification2" name="qualification" value="Qualification 3" />
                                        </div>
                                        <div style={divStyle}>
                                            <label htmlFor="qualification2">Others:</label>
                                            <input type="radio" id="qualification4" name="qualification" value="Qualification 4" />
                                        </div>
                                    </div>
                                    {/* Add more options as needed */}
                                </div>
                                <div className="form-group">
                                    <label style={labelLeft}>Years Attended:</label>
                                    <div className="years-attended" >
                                        <input type="text" name="startYear" placeholder="From XXXX" />
                                        <span style={labelLeft}>to</span>
                                        <input type="text" name="endYear" placeholder="To XXXX" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-section">
                                <div className="form-group">
                                    <label style={labelLeft}>Insert receipt of your transfer here*:</label>
                                    <input type="file" name="pdfFile" accept=".pdf" onChange={handleFileChange} required />
                                </div>
                            </div>
                            {/* Submit Button */}
                            <div className="form-group">
                                <button type="submit" className="lab-btn">{btnText}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            
            <Footer />
        </Fragment>
    );
}
 
export default RegisterPage;