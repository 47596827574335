import { Link } from "react-router-dom";
import banner from "../../assets/images/banner/Building.jpg";

const subTitle = "CRAFTING VISIONARIES IN DESIGN EDUCATION";
const title = <h2 className="title"><span className="d-lg-block">Experience</span> Real-World Learning <span className="d-lg-block">and Innovation at</span><span className="d-lg-block">Ed Mun Academy</span></h2>;
const desc = "Discover your potential at Ed Mun Academy. Embrace design excellence- Start your journey today!";

// not being used
// const categoryList = [
//     {
//         name: 'Figma',
//         link: '#',
//     },
//     {
//         name: 'Adobe XD',
//         link: '#',
//     },
//     {
//         name: 'illustration',
//         link: '#',
//     },
//     {
//         name: 'Photoshop',
//         link: '#',
//     },
// ]

// change the hovering titles
//commented because not suitable/needed
// removed links
// const shapeList = [
    
    // {
    //     name: 'Suitable for everyone',
    //     // link: '#',
    //     className: 'ccl-shape shape-1',
    // },
    // {
    //     name: 'Learning Never Stops',
    //     // link: '#',
    //     className: 'ccl-shape shape-2',
    // },
    // {
    //     name: '100% Satisfied Students',
    //     // link: '#',
    //     className: 'ccl-shape shape-3',
    // },
    // {
    //     name: '5 different courses',
    //     // link: '#',
    //     className: 'ccl-shape shape-4',
    // },
    // {
    //     name: 'Learn with our professionals',
    //     // link: '#',
    //     className: 'ccl-shape shape-5',
    // },
// ]
const sectStyle = {
    paddingBottom:80,
};

const btnStyle = {
    backgroundColor: '#F68B1F',
    color: '#fff',
    fontWeight: 700,
    padding: '10px 30px',
    letterSpacing: 1.25,
}

const Banner = () => {
    return (
        <section className="banner-section" style = {sectStyle}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-10">
                            <div className="banner-content">
                                <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>
                                {title}
                                <p className="desc">{desc}</p>
                                {/* <Link to="#course-sec"><a style={btnStyle}>Our Programs</a></Link> */}
                                {/* <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Keywords of your course" /> not using search in banner
                                    
                                </form>  */}
                                {/* <div className="banner-category d-flex flex-wrap">
                                    <p>Most Popular : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {categoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6">
                            <div className="banner-thumb">
                                <img src={banner} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* not being utilised */}
            {/* <div className="all-shapes"></div>
            <div className="cbs-content-list d-none">
                <ul className="lab-ul">
                    {shapeList.map((val, i) => (
                        <li className={val.className} key={i}><a href={val.link}>{val.name}</a></li>
                    ))}
                </ul>
            </div> */}
        </section>
    );
}
 
export default Banner;