import  { React, useState } from 'react';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';

// const newsTitle = "Want Us To Email You About Special Offers And Updates?";
// const siteTitle = "Site Map";
// const useTitle = "Useful Links";
// const socialTitle = "Social Contact";
// const supportTitle = "Our Support";

// const siteList = [
//     {
//         text: 'Documentation',
//         link: '#',
//     },
//     {
//         text: 'Feedback',
//         link: '#',
//     },
//     {
//         text: 'Plugins',
//         link: '#',
//     },
//     {
//         text: 'Support Forums',
//         link: '#',
//     },
//     {
//         text: 'Themes',
//         link: '#',
//     },
// ]

// const useList = [
//     {
//         text: 'About Us',
//         link: '#',
//     },
//     {
//         text: 'Help Link',
//         link: '#',
//     },
//     {
//         text: 'Terms & Conditions',
//         link: '#',
//     },
//     {
//         text: 'Contact Us',
//         link: '#',
//     },
//     {
//         text: 'Privacy Policy',
//         link: '#',
//     },
// ]

// const socialList = [
//     {
//         text: 'Facebook',
//         link: '#',
//     },
//     {
//         text: 'Twitter',
//         link: '#',
//     },
//     {
//         text: 'Instagram',
//         link: '#',
//     },
//     {
//         text: 'YouTube',
//         link: '#',
//     },
//     {
//         text: 'Github',
//         link: '#',
//     },
// ]

// const supportList = [
//     {
//         text: 'Help Center',
//         link: '#',
//     },
//     {
//         text: 'Paid with Mollie',
//         link: '#',
//     },
//     {
//         text: 'Status',
//         link: '#',
//     },
//     {
//         text: 'Changelog',
//         link: '#',
//     },
//     {
//         text: 'Contact Support',
//         link: '#',
//     },
// ]

const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
}
const rightStyle = {
    display: 'flex',
    justifyContent: 'right',
}
const leftStyle = {
    display: 'flex',
    justifyContent: 'left',
}
const footerBg = {
    backgroundColor: '#000',
}
const footerText = {
    color: '#fff',
    fontSize:'13px',
    alignSelf: 'center',
}
// const footerLink = {
//     fontSize:'13px',
// }

const Footer = () => {
    const [isHovered, setIsHovered] = useState(false);
    const footerLink = {
        color: isHovered ? '#F68B1F':'#FFF',
        textDecoration: 'none',
    }
    return (
        <div className="news-footer-wrap">
            <footer>
                <div className="footer-bottom style-2"  style={footerBg}>
                    <div className="container">
                        <div className="section-wrapper" style={rowStyle}>
                            <Col style={leftStyle}>
                                <p style={footerText}>&copy; 2023 <Link to="/" style={footerLink} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}>Ed Mun Academy.</Link> All Rights Reserved. Disclaimer | <Link to="/" style={footerLink} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}>Privacy Policy</Link></p> 
                            </Col>
                            <Col style={rightStyle}>
                                <p style={footerText}>Developed by <Link to="/" style={footerLink} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}>Signcotive Sdn Bhd.</Link></p>
                            </Col>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
 
export default Footer;