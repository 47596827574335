
import { Link } from "react-router-dom";
// import Rating from "../sidebar/rating";

const subTitle = "Featured Programs";
const title = "FIND YOUR OPPORTUNITIES HERE";

const courseSection = {
    backgroundColor: '#fff',
}

const titleStyle = {
    color: '#F68B1F',
    fontWeight: 700,
    letterSpacing: 1.25,
}
// const btnStyle = {
//     backgroundColor: '#F68B1F',
//     color: '#fff',
//     fontWeight: 700,
//     padding: '10px 30px',
//     letterSpacing: 1.25,
// }
const courseList = [
    {
        imgUrl: 'assets/images/course/EDMA-Mentorship_01.jpg',
        imgAlt: 'course 1',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Mentorship Program',
        program:'Entry Level',
        slug: '/mentorship',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma3.jpg',
        authorImgAlt: 'course author 1',
        authorName: 'Prarthana',
        btnText: 'Mentorship Program Entry Level',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-Mentorship_02.jpg',
        imgAlt: 'course 2',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Mentorship Program',
        program:' Professional Level',
        slug: '/mentorship-pro',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma3.jpg',
        authorImgAlt: 'course author 2',
        authorName: 'Prarthana',
        btnText: 'Mentorship Program Professional Level',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-Licensing_01.jpg',
        imgAlt: 'course 3',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Licensing Program',
        program:'Entry Level',
        slug: '/licensing',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma2.jpg',
        authorImgAlt: 'course author 3',
        authorName: 'Esther Ng',
        btnText: 'Licensing Program Entry Level',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-Licensing_02.jpg',
        imgAlt: 'course 4',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Licensing Program',
        program:'Professional Level',
        slug: '/licensing-pro',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma2.jpg',
        authorImgAlt: 'course author 4',
        authorName: 'Esther Ng',
        btnText: 'Licensing Program Professional Level',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-interior-Styling.jpg',
        imgAlt: 'course 5',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Interior Styling',
        program:'Program',
        slug: '/interior-styling',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma1.jpg',
        authorImgAlt: 'course author 5',
        authorName: 'Ed Mun',
        btnText: 'Interior Styling Program',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-Interior-design.jpg',
        imgAlt: 'course 6',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Interior Design',
        program:'Program',
        slug: '/interior-design',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma2.jpg',
        authorImgAlt: 'course author 6',
        authorName: 'Esther Ng',
        btnText: 'Interior Design Course',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-architecturee.jpg',
        imgAlt: 'course 7',
        price: 'RM600',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Architecture',
        program:'Program',
        slug: '/architecture',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma1.jpg',
        authorImgAlt: 'course author 7',
        authorName: 'Ed Mun',
        btnText: 'Architecture Course',
        enrollTxt: 'ENROLL NOW',
    },
    {
        imgUrl: 'assets/images/course/EDMA-Multimedia.jpg',
        imgAlt: 'course 8',
        price: 'RM888',
        // cate: 'Adobe XD',
        // reviewCount: '03 reviews',
        title:'Multimedia',
        program:'Program',
        slug: '#',
        totalLeson: '18x Lesson',
        schdule: 'Online Class',
        authorImgUrl: 'assets/images/course/author/edma3.jpg',
        authorImgAlt: 'course author 8',
        authorName: 'Prarthana',
        btnText: 'Multimedia Course',
        enrollTxt: 'COMING SOON',
    },
]

const Course = () => {
    const getLastThreeIndices = (arrayLength) => {
        const lastThreeIndices = [];
        for (let i = 1; i <= Math.min(arrayLength, 1); i++) {
            lastThreeIndices.push(arrayLength - i);
        }
        return lastThreeIndices;
    };
    
    return (
        <div id="course-sec" className="course-section padding-tb section-bg" style={courseSection}>
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1">
                        {courseList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="course-content">
                                            <div className="course-footer">
                                                <div className="course-btn">
                                                    <Link to="#" className="lab-btn-text" style={titleStyle}>{val.title}<br/>{val.program}</Link>
                                                </div>
                                                <div className="course-author">
                                                    {/* <img src={`${val.authorImgUrl}`} alt={`${val.authorImgAlt}`} /> */}
                                                    <Link to={val.slug} className="ca-name"><button className={getLastThreeIndices(courseList.length).includes(i) ? 'btnStyle-two' : 'btnStyle'}>{val.enrollTxt}</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Course;