import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../assets/css/responsive.css';

// const btnText = "Browse All Programs";
// const licItemsSpan ={
//     textAlign: 'justify',
// }
// const licItemsHeading ={
//     paddingBottom: 10,
// }
// const licItemsInner = {
//     padding: 15,
// }
// const licenseHeader = {
//     marginBottom:'0px'
// }

const btnRow ={
    display: 'flex',
    // justifyContent: 'center',
}
const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
}
const colStyle = {
    display: 'flex',
    flexDirection: 'column',
}
const btnStyle = {
    backgroundColor: '#F68B1F',
    color: '#fff',
    fontWeight: 700,
    padding: '10px 30px',
    letterSpacing: 1.25,
}
const imgStyle = {
    display: 'flex',
    justifyContent: 'center', // Horizontally centers the flex items
    alignSelf: 'center', // Vertically centers the individual item within the flex container
    textAlign: 'center',
    maxWidth: '80px',
}

const licenseRow = {
    marginTop: '25px',
    marginBottom: '25px',
}
const licensePara = {
    marginBottom: '40px',
}
const licenseParaTitle = {
    marginBottom: '15px',
}
const licenseTitle = {
    color: '#F68B1F',
    letterSpacing: 1.1,
}
const licenseSection = {
    backgroundColor: '#fff9f1',
}

const colCenter = {
    alignSelf: 'center',
}

const LicenseSua = () => {
    return (
        <div className="category-section padding-tb" style={licenseSection}>
            <div className="container">
                <div className="section-wrapper">
                    <Container>
                        <Row style={rowStyle}>
                            {/* text section */}
                            <Col lg={6} xs={12} className='license-sua-order'>
                                <Row style={licensePara}>
                                    <h2 style={licenseParaTitle}>EDMA's First-in-Malaysia Interior Design TVET Program.</h2>
                                    <span>EDMA's Interior Design TVET Program, first in Malaysia, provides comprehensive, expert-led training in design innovation. It combines theoretical knowledge with practical experience, preparing students for success in the dynamic interior design industry. This program is your gateway to becoming a skilled, industry-ready designer.
                                    </span>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-expert-lead-training.png" alt="" /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Expert-Led Training</h5><span>Instruction from top professionals in the design industry.</span></Col>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-hands-on-learning.png" alt="" /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Practical, Hands-On Learning</h5><span>Emphasis on real-world experience and project involvement.</span></Col>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-industry-relevant-curriculum.png" alt="" /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Industry-Relevant Curriculum</h5><span>Up-to-date content tailored to current market trends and needs.</span></Col>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-career-ready-skill-development.png" alt=""  /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Career-Ready Skills Development</h5><span>Focused on equipping students with skills essential for immediate industry entry and success.</span></Col>
                                </Row>
                                <Row style={btnRow}>
                                    <Link to="register"><a style={btnStyle}>ENROLL NOW</a></Link>
                                </Row>
                            </Col>
                            {/* image section */}
                            <Col lg={6} xs={12} className='license-sua-img'>
                                <Row style={colStyle}>
                                    {/* img 1 */}
                                    <Col>
                                        <img src="assets/images/license-edma/EDMA-Expert-Lead-Training_01.jpg" alt="" />
                                    </Col>
                                    <Col>
                                        <Row style={rowStyle}>
                                            {/* img 2 */}
                                            <Col>
                                                <Row style={colStyle}>
                                                    {/* img 3 & 4 */}
                                                    <Col>
                                                        <img src="assets/images/license-edma/EDMA-practical-hands-on-learning.jpg" alt="" style={{marginTop: '20px', marginBottom: '20px'}} />
                                                    </Col>
                                                    <Col>
                                                        <img src="assets/images/license-edma/EDMA-Career-Ready-Skills-Development.jpg" alt="" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <img src="assets/images/license-edma/EDMA-industry-relevant-curriculum.jpg" alt="" style={{marginTop: '40px', marginBottom: '20px'}}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}
 
export default LicenseSua;