import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo/logo1.png"

const phoneNumber = "+60 12 221 1793";
const address = "Unit 9.12, Level 9, Block F, Phileo Damansara 1, Jalan 16/11, Seksyen 16, 46350 Petaling Jaya, Selangor Darul Ehsan, Malaysia";
const btnStyle = {
    backgroundColor: '#F68B1F',
    color: '#fff',
    fontWeight: 700,
    padding: '10px 25px',
    letterSpacing: 1.25,
}
// not using any of these social media icons
// let socialList = [
//     {
//         iconName: 'icofont-facebook-messenger',
//         siteLink: '#',
//     },
//     {
//         iconName: 'icofont-twitter',
//         siteLink: '#',
//     },
//     {
//         iconName: 'icofont-vimeo',
//         siteLink: '#',
//     },
//     {
//         iconName: 'icofont-skype',
//         siteLink: '#',
//     },
//     {
//         iconName: 'icofont-rss-feed',
//         siteLink: '#',
//     },
// ]

const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFixed, setheaderFixed] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setheaderFixed(true);
		} else {
			setheaderFixed(false);
		}
	});

    return (
        <header className={`header-section ${headerFixed ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <ul className="lab-ul left">
                            <li><i className="icofont-ui-call"></i> <span>{phoneNumber}</span></li>
                            <li><i className="icofont-location-pin"></i> {address}</li>
                            {/* <li className={`lab-ul menu-item-has-children ${menuToggle ? "active" : ""}`}>
                                En
                                <a href="#" style={langBtn} role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"></a>
                                <ul className="lab-ul dropdown-menu">
                                    <li><NavLink to="#">En</NavLink></li>
                                    <li><NavLink to="#">BM</NavLink></li>
                                    <li><NavLink to="#">Cn</NavLink></li>
                                </ul>
                            </li> */}
                        </ul>
                        
                        {/* <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                            ))}
                        </ul> */}
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <Link to="/"><img src={logo} alt="logo"/></Link>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li><NavLink to="/">HOME</NavLink></li>
                                    <li><NavLink to="/about">ABOUT</NavLink></li>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">PROGRAMS</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/interior-styling">Interior Styling</NavLink></li>
                                            <li><NavLink to="/licensing">Licensing (Entry)</NavLink></li>
                                            <li><NavLink to="/licensing-pro">Licensing (Pro)</NavLink></li>
                                            <li><NavLink to="/mentorship">Mentorship (Entry)</NavLink></li>
                                            <li><NavLink to="/mentorship-pro">Mentorship (Pro)</NavLink></li>
                                            <li><NavLink to="/interior-design">Interior Design (BTEC)</NavLink></li>
                                            <li><NavLink to="/architecture">Architecture (TVET)</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink to="/register">ADMISSION</NavLink></li>
                                    <li><NavLink to="/contact">CONTACT US</NavLink></li>
                                </ul> 
                            </div>
                            
                            {/* <Link to="/login" className="login"><i className="icofont-user"></i> <span>LOG IN</span> </Link>*/}
                            <Link to="/register" className="signup"> <span style={btnStyle}>ENROLL NOW</span> </Link> {/*<i className="icofont-users"></i> */}

                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;