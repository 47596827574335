
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../assets/css/responsive.css';

const linkStyle ={
    color: '#fff',
}
const btnRow ={
    display: 'flex',
    // justifyContent: 'center',
}
const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
}
const colStyle = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
}
const btnStyle = {
    display: 'flex',
    backgroundColor: '#F68B1F',
    color: '#fff',
    fontWeight: 700,
    padding: '10px 25px',
    letterSpacing: 1.25,
    width: 'auto',
    textAlign: 'center',
    marginTop: '20px',
}
const imgStyle = {
    display: 'flex',
    justifyContent: 'center', // Horizontally centers the flex items
    alignSelf: 'center', // Vertically centers the individual item within the flex container
    textAlign: 'center',
    maxWidth: '80px',
}
// const subTitle = "Popular Programs";
// const title = "Popular Programs For Learning";   
// const btnText = "Browse All Programs";

const licenseRow = {
    marginTop: '25px',
    marginBottom: '25px',
}
const licensePara = {
    marginBottom: '40px',
}
const licenseParaTitle = {
    marginBottom: '15px',
}
const licenseTitle = {
    color: '#F68B1F',
    letterSpacing: 1.1,
}
// const licenseHeader = {
//     marginBottom:'0px'
// }
const licenseSection = {
    backgroundColor: '#fff9f1',
}
// const licItemsSpan ={
//     textAlign: 'justify',
// }
// const licItemsHeading ={
//     paddingBottom: 10,
// }
// const licItemsInner = {
//     padding: 15,
// }
const colCenter = {
    alignSelf: 'center',
}


const LicenseSua = () => {
    return (
        <div className="category-section padding-tb" style={licenseSection}>
            <div className="container">
                {/* <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div> */}
                <div className="section-wrapper">
                    <Container>
                        <Row style={rowStyle}>
                            {/* image section */}
                            <Col lg={6} xs={12} className='license-sua-img'>
                                <Row style={colStyle}>
                                    {/* img 1 */}
                                    <Col xs={12}>
                                        <img src="assets/images/license-edma/EDMA-Skilled-instructor-01.jpg" alt="" />
                                    </Col>
                                    <Col>
                                        <Row style={rowStyle}>
                                            {/* img 2 */}
                                            <Col>
                                                <img src="assets/images/license-edma/EDMA-comprehensive-support.jpg" alt=""  style={{marginTop: '40px', marginBottom: '20px'}}/>
                                            </Col>
                                            <Col>
                                                <Row style={colStyle}>
                                                    {/* img 3 & 4 */}
                                                    <Col>
                                                        <img src="assets/images/license-edma/EDMA-management-training.jpg" alt="" style={{marginTop: '20px', marginBottom: '20px'}}/>
                                                    </Col>
                                                    <Col>
                                                        <img src="assets/images/license-edma/EDMA-Networking-Opportunities.jpg" alt="" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            {/* text section */}
                            <Col>
                                <Row style={licensePara}>
                                    <h2 style={licenseParaTitle}>Transform Your Vision into Reality with SUA Licensing</h2>
                                    <span>The SUA Interior Design Licensing Program is a gateway for professional designers to own and operate their studios. Offering essential resources, expert support, and access to a vast network, this program equips you with everything needed to launch a successful design business. Embrace this opportunity to transform your professional aspirations into a flourishing reality.</span>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-skilled-instructor-icon.png" alt="" /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Skilled Instructors</h5><span>Gain insights and skills from top interior design experts.</span></Col>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-management-training-icon.png" alt="" /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Management Training</h5><span>Acquire essential business and studio management skills.</span></Col>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-comprehensive-support-icon.png" alt="" /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Comprehensive Support</h5><span>Access marketing, operational, and HR guidance.</span></Col>
                                </Row>
                                <Row style={licenseRow}>
                                    <Col xs={2} style={imgStyle}><img src="assets/images/license-edma/icons/EDMA-networking-opportunities.png" alt=""  /></Col>
                                    <Col style={colCenter}><h5 style={licenseTitle}>Networking Opportunities</h5><span>Connect with industry professionals for growth and collaboration</span></Col>
                                </Row>
                                <Row style={btnRow}>
                                    <button style={btnStyle}><Link to="/licensing" style={linkStyle}>EXPLORE MORE</Link></button>
                                </Row>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}
 
export default LicenseSua;