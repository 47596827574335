import {  Fragment } from "react"; //Component,
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import CourseSideCategory from "../component/sidebar/course-category";
import CourseSideDetail from "../component/sidebar/course-detail";
// import Respond from "../component/sidebar/respond";

const dropDown = {
    backgroundColor: '#f68b1f',
}
const iconColor = {
    color: '#f68b1f',
}
const Multimedia = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo />
            <div className="interior-design-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Course Overview</h3>
                                            <p>Interior Styling Mastery is a comprehensive program designed to equip individuals with the knowledge, skills, and confidence to excel in the dynamic field of interior design. From mastering the basics of design principles to navigating the complexities of starting a successful interior design business, this course provides a holistic understanding of the industry.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Introduction to Interior Styling.</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Colors.</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Design Basics.</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Furniture and Soft Furnishing selections.</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Mood Board or Concept Board Creation.</li>
                                                <li><i className="icofont-tick-mark" style={iconColor}></i>Starting your own Interior Design Business.</li>
                                            </ul>
                                            <p>
                                                The role of an interior stylist spans planning and executing additions,subtractions, or collations involved in the styling of an interior. 
                                            </p>
                                            <p>
                                                Upon completion, participants will possess a robust skill set and knowledge base necessary to excel as interior stylists or entrepreneurs in the competitive realm of interior design.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="course-video">
                                    <div className="course-video-title">
                                        <h4>Course Content</h4>
                                    </div>
                                    <div className="course-video-content">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion01">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist1" aria-expanded="true" aria-controls="videolist1" style={dropDown}><span>1. Introduction</span> <span>5 lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist1" className="accordion-collapse collapse show" aria-labelledby="accordion01" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.1 What is Interior Styling?</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">1.2 Fundamentals of Design</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion02">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist1" style={dropDown}><span>2. Colors</span> <span>5 lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist2" className="accordion-collapse collapse show" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.1 Color schemes in interior design</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.2 Understanding colors and their application to enhance interiors</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.3 Workshop: Using color texture on walls, furniture, and more</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.4 Importance of lighting in interior design to enhance spaces and mood</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.5 Sustainability for health</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion03">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist3" aria-expanded="true" aria-controls="videolist3" style={dropDown}><span>3. Design Basics</span> <span>5 lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist3" className="accordion-collapse collapse show" aria-labelledby="accordion03" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.1 Colors</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.2 Design Basics</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.3 Furniture and Soft Furnishing selections</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.4 Mood Board or Concept Board Creation</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">3.5 Starting your own Interior Design Business</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion04">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist4" aria-expanded="true" aria-controls="videolist4" style={dropDown}><span>4. Furniture and Soft Furnishing selections</span> <span>5 lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist4" className="accordion-collapse collapse show" aria-labelledby="accordion04" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.1 Impact of furniture and soft furnishings on the overall environment</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">4.2 Tips for selecting the right pieces</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion05">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist5" aria-expanded="true" aria-controls="videolist5" style={dropDown}><span>5. Mood Board or Concept Board Creation</span> <span>5 lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist5" className="accordion-collapse collapse show" aria-labelledby="accordion05" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.1 Freehand sketching techniques</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.2 Develop your own purposeful storyboard</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.3 The role of mood boards in showcasing taste and credibility</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">5.4 Developing a sense of taste</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion06">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist6" aria-expanded="true" aria-controls="videolist6" style={dropDown}><span>6. Starting your own Interior Design Business</span> <span>5 lessons, 17:37</span> </button>
                                                </div>
                                                <div id="videolist6" className="accordion-collapse collapse show" aria-labelledby="accordion06" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">6.1 Pricing and charging strategies</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">6.2 Earning potential as an interior stylist</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">6.3 Methods to achieve five-figure monthly income</div>
                                                            {/* <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className="accordion-item">
                                                <div className="accordion-header" id="accordion02">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist2"> <span>2.How to Create Mixed Media Art in Adobe Photoshop</span> <span>5 lessons, 52:15</span> </button>
                                                </div>
                                                <div id="videolist2" className="accordion-collapse collapse" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.1 Using Adjustment Layers 06:20 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.2 Building the composition 07:33 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.3 Photoshop Lighting effects 06:30 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.4 Digital Painting using photoshop brushes 08:34 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                            <div className="video-item-title">2.5 Finalizing the details 10:30 minutes</div>
                                                            <div className="video-item-icon"><a href="#" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <Author />
                                <Comment />
                                <Respond /> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                                {/* <CourseSideCategory /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Multimedia;