import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";

const subTitle = "About Us";
const title = "Good Qualification And Better Skills";
const desc = "Ed Mun Academy stands at the forefront of interior design education in Malaysia, founded by the renowned Ed Mun, a visionary in the field with a rich legacy in SUA Interior Design. As a pioneering institution, the academy specializes in imparting practical knowledge and creative skills directly from seasoned industry experts.";

const year = "30+";
const expareance = "Years Of Experiences";

const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/Untitled design.png',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Vision',
        desc: 'Empower all through education to change the world.',
    },
    {
        imgUrl: 'assets/images/about/icon/Untitled design (1).png',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Mission',
        desc: "Dedicated to accessible knowledge and versatile skills through in-depth education , vocational and training. \nProvide real-world job exposure, locally and internationally, guaranteeing employment. \nAspire to global recognition, embracing limitless creativity. \nOur holistic teachers drive global change, supporting private and government sectors in achieving a well-developed skill set for economic and national development.",
    },
]

const AboutPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'About Us'} curPage={'About'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/EDMA-about-Us_01.jpg" alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src="assets/images/about/EDMA-about-us-02.jpg" alt="about" />
                                </div>
                                {/* <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                {/* <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div> */}
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Student />
            {/* <Instructor />
            <Skill />
            <AchievementTwo />
            <Blog /> */}
            <Footer />
        </Fragment>
    );
}

export default AboutPage;
 